import { forwardRef } from 'react';
import classNames from 'classnames';
import { withStyles } from '@jpmuitk/theme';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form';
import isObject from 'lodash/isObject';

import { Input } from '@jpmuitk/input';
import { FormField } from '@jpmuitk/form-field';
import { ColumnLayout } from '@jpmuitk/column-layout';

import ConditionalWrapper from 'Components/ConditionalWrapper';
import { columnLayoutItemSizes } from 'Components/Forms/helpers';
import { useInteractiveProps, useErrorProps } from 'Components/Forms/Hooks';

import { callAll } from 'utility/renderProps';
import { composeValidators, processError } from 'utility/validation';

import { balanceStyles } from './styles';
import { checkboxStyles } from 'styles';

const InputBuilder = forwardRef((props, ref) => {
  const {
    ariaLabel,
    autoFocus,
    classes,
    debouncedValidator,
    errorOnLoad,
    errorOverride,
    fieldRest,
    format,
    helperText,
    helperTextPlacement,
    hide,
    id,
    input,
    inputProps,
    isDisabled,
    isRequired,
    isValidationDisabled,
    itemToString,
    label,
    labelPlacement,
    maxRangeValue,
    meta,
    mustBalance,
    noSuffix,
    onBlur,
    onChange,
    parse,
    propsRest: { validator, validationType, ...propsRest },
    readOnly,
    unformatter,
    unwrapped,
    validation,
    validateOnLoad,
    LabelProps,
    TooltipProps,
    // Layout
    columnLayoutProps,
    useColumnLayout,
    placeholder
  } = useInteractiveProps(props);

  const { t } = useTranslation();
  if (!unwrapped) {
    const {
      input: { onChange: inputOnChange, onBlur: inputOnBlur, ...input },
      meta
    } = useField(`${id}${noSuffix ? '' : 'Field'}`, {
      key: isRequired ? 1 : 0,
      format,
      parse,
      validate: composeValidators(validation({ isRequired, unformatter, maxRangeValue })),
      type: inputProps.type
    });

    const { type, msg } = useErrorProps({ meta, validateOnLoad, isValidationDisabled, errorOnLoad, errorOverride });
    const updatedProps = msg && msg.length > 0 ? { 'aria-describedby': 'errorMsg' + id } : {}; // update for local error announcement
    const { value, ...mergedInputProps } = !isDisabled
      ? {
          ...inputProps,
          ...input,
          'aria-invalid': type === 'error',
          'aria-required': isRequired,
          autoComplete: 'off',
          'aria-label': ariaLabel({ t }),
          ...updatedProps,
          classes: undefined
        }
      : {};

    return hide ? null : (
      <ConditionalWrapper
        condition={useColumnLayout}
        wrapper={children => (
          <ColumnLayout item {...columnLayoutProps}>
            {children}
          </ColumnLayout>
        )}
      >
        {inputProps.type === 'checkbox' ? (
          <Checkbox
            input={input}
            inputProps={{
              ...inputProps,
              onChange: e => {
                inputOnChange(e);
                onChange && onChange(e);
              }
            }}
            placeholder={placeholder}
            isDisabled={isDisabled}
            labelPlacement={labelPlacement}
            readOnly={readOnly}
            label={label({ t })}
          />
        ) : (
          <FormField
            validationState={type}
            helperText={msg}
            helperTextPlacement={helperTextPlacement}
            label={label({ t })}
            labelPlacement={labelPlacement}
            disabled={isDisabled}
            readOnly={readOnly}
            LabelProps={{
              NecessityIndicator: () => (!readOnly && isRequired ? <i>&nbsp;{t('necessity.required')}</i> : ''),
              ...LabelProps
            }}
            TooltipProps={TooltipProps}
            {...fieldRest}
            {...propsRest}
          >
            {msg && (
              <span id={'errorMsg' + id} className="visually-hidden">
                {msg}
              </span>
            )}
            <Input
              inputRef={ref}
              autoFocus={autoFocus}
              inputProps={{
                ...mergedInputProps
              }}
              value={value === '' ? null : isObject(value) && itemToString ? itemToString(value) : value}
              classes={{
                field: classNames(
                  { [classes.balance]: parseFloat(input.value) && mustBalance },
                  inputProps.classes?.field
                ),
                ...inputProps.classes
              }}
              onChange={e => {
                inputOnChange(e);
                onChange && onChange(e);
              }}
              onBlur={e => {
                onBlur && onBlur(e);
              }}
              readOnly={readOnly}
              emptyReadOnlyMarker={''}
            />
          </FormField>
        )}
      </ConditionalWrapper>
    );
  } else {
    const { type, msg } = useErrorProps({ meta, validateOnLoad, isValidationDisabled, errorOnLoad });

    return hide ? null : (
      <ConditionalWrapper
        condition={useColumnLayout}
        wrapper={children => (
          <ColumnLayout item {...columnLayoutProps}>
            {children}
          </ColumnLayout>
        )}
      >
        {inputProps.type === 'checkbox' ? (
          <Checkbox
            input={input}
            onChange={e => {
              input.onChange(e);
              onChange && onChange(e);
            }}
            inputProps={inputProps}
            isDisabled={isDisabled}
            labelPlacement={labelPlacement}
            readOnly={readOnly}
            label={label({ t })}
          />
        ) : (
          <FormField
            autoFocus={autoFocus}
            // required={isRequired}
            validationState={validationType || type}
            helperText={msg}
            label={label({ t })}
            labelPlacement={labelPlacement}
            inputProps={
              !isDisabled && {
                inputProps: {
                  ...inputProps,
                  ...input,
                  autoComplete: 'off',
                  'aria-required': isRequired,
                  'aria-invalid': type === 'error'
                }
              }
            }
            disabled={isDisabled}
            readOnly={readOnly}
            LabelProps={LabelProps}
            TooltipProps={TooltipProps}
            {...fieldRest}
            {...propsRest}
          >
            <Input
              inputRef={ref}
              autoFocus={autoFocus}
              inputProps={
                !isDisabled && {
                  ...inputProps,
                  ...input,
                  'aria-required': isRequired,
                  autoComplete: 'off',
                  onChange: e => callAll(validator?.(e.target), input?.onChange?.(e))
                }
              }
              readOnly={readOnly}
              emptyReadOnlyMarker={''}
              defaultValue={itemToString ? itemToString(propsRest?.defaultValue) : propsRest?.defaultValue}
            />
          </FormField>
        )}
      </ConditionalWrapper>
    );
  }
});

const CheckboxDefault = ({ input, inputProps, isDisabled, labelPlacement, readOnly, label, classes, placeholder }) => {
  if (readOnly) {
    inputProps.onChange = undefined;
  }
  return (
    <div className={classNames(classes.root, classes[`labelPlacement${labelPlacement}`])}>
      <input
        id={input.name}
        className={classes.input}
        {...inputProps}
        {...input}
        disabled={isDisabled}
        readOnly={readOnly}
      />
      <label for={input.name} className={classes.label}>
        {placeholder}
      </label>
      <label for={input.name} className={classes.label}>
        {label}
      </label>
    </div>
  );
};

const Checkbox = withStyles(checkboxStyles)(CheckboxDefault);

export default withStyles(balanceStyles)(InputBuilder);
