import {
  LOAD_DECISION_MANAGER_HISTORY_REQUEST,
  LOAD_DECISION_MANAGER_HISTORY_SUCCESS,
  LOAD_DECISION_MANAGER_HISTORY_FAILURE,
  EXPORT_DECISION_REQUEST,
  EXPORT_DECISION_SUCCESS,
  EXPORT_DECISION_FAILURE,
  ASSIGN_DECISION_MANAGER_REQUEST,
  ASSIGN_DECISION_MANAGER_SUCCESS,
  ASSIGN_DECISION_MANAGER_FAILURE,
  LOAD_ASSIGNABLE_USERS_REQUEST,
  LOAD_ASSIGNABLE_USERS_SUCCESS,
  LOAD_ASSIGNABLE_USERS_FAILURE,
  CLEAR_DECISION_MANAGER
} from './decisionManagerActions';

const initialState = {
  data: {},
  dataError: {},
  workflowAssignState: {},
  isFetchingData: true
};

const clearDecisionManager = (state, action) => {
  return {
    ...state,
    dataError: {},
    workflowAssignState: {},
    isFetchingData: true,
    data: {},
    isFetchingExport: false,
    exportError: null,
    exportData: null
  };
};

const loadDecisionManagerHistoryRequest = state => {
  return {
    ...state,
    data: {},
    dataError: {},
    isFetchingData: true
  };
};

const loadDecisionManagerHistorySuccess = (state, action) => {
  return {
    ...state,
    dataError: {},
    isFetchingData: false,
    data: action.response.data
  };
};

const loadDecisionManagerHistoryFailure = (state, action) => {
  return {
    ...state,
    data: {},
    isFetchingData: false,
    dataError: action.error
  };
};

const exportDecisionsRequest = (state, action) => {
  return {
    ...state,
    isFetchingExport: true,
    exportError: null,
    exportData: null
  };
};

const exportDecisionsSuccess = (state, action) => {
  return {
    ...state,
    exportData: action.response,
    isFetchingExport: false
  };
};

const exportDecisionsFailure = (state, action) => {
  return {
    ...state,
    exportError: action.error,
    isFetchingExport: false
  };
};

const assignDecisionRequest = (state, action) => {
  return {
    ...state,
    data: {},
    isFetchingData: true
  };
};

const assignDecisionSuccess = (state, action) => {
  return {
    ...state,
    isFetchingData: false,
    data: action.response
  };
};

const assignDecisionFailure = (state, action) => {
  return {
    ...state,
    isFetchingData: false,
    error: action.error
  };
};

const loadUserRequest = (state, action) => {
  return {
    ...state,
    workflowAssignState: {
      errorFetchingUsers: undefined,
      availableUsers: [],
      fetching: true
    }
  };
};

const loadUserSuccess = (state, action) => {
  return {
    ...state,
    workflowAssignState: {
      fetching: false,
      availableUsers: action.response.data
    }
  };
};

const loadUserFailure = (state, action) => {
  return {
    ...state,
    workflowAssignState: {
      errorFetchingUsers: action.error,
      availableUsers: [],
      fetching: false
    }
  };
};
export default function decisionManagerReducer(state = initialState, action) {
  const handlers = {
    [LOAD_DECISION_MANAGER_HISTORY_REQUEST]: loadDecisionManagerHistoryRequest,
    [LOAD_DECISION_MANAGER_HISTORY_SUCCESS]: loadDecisionManagerHistorySuccess,
    [LOAD_DECISION_MANAGER_HISTORY_FAILURE]: loadDecisionManagerHistoryFailure,
    [EXPORT_DECISION_REQUEST]: exportDecisionsRequest,
    [EXPORT_DECISION_SUCCESS]: exportDecisionsSuccess,
    [EXPORT_DECISION_FAILURE]: exportDecisionsFailure,
    [ASSIGN_DECISION_MANAGER_REQUEST]: assignDecisionRequest,
    [ASSIGN_DECISION_MANAGER_SUCCESS]: assignDecisionSuccess,
    [ASSIGN_DECISION_MANAGER_FAILURE]: assignDecisionFailure,
    [LOAD_ASSIGNABLE_USERS_REQUEST]: loadUserRequest,
    [LOAD_ASSIGNABLE_USERS_SUCCESS]: loadUserSuccess,
    [LOAD_ASSIGNABLE_USERS_FAILURE]: loadUserFailure,
    [CLEAR_DECISION_MANAGER]: clearDecisionManager
  };

  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
