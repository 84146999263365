import React from 'react';
import { useDispatch } from 'react-redux';
import { ContentStatus } from '@jpmuitk/content-status';
import UploadFile from 'Components/UploadFileButton';
import { Trans, useTranslation } from 'react-i18next';
import { withStyles } from '@jpmuitk/theme';
import { uploadFileZoneStyles } from './styles';
import classNames from 'classnames';

export const handleUploadData = (event, func) => {
  const file = event.target.files[0];
  const formData = new FormData();
  formData.append('file', file);
  func(formData);
};

export const UploadFileZone = props => {
  const {
    classes,
    fileErrorMessage,
    uploadFile,
    uploadFileInfo,
    uploadSuccess,
    uploadStatus,
    disabled = false
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loadingProps = {
    status: 'loading',
    message: t('pageWithUpload.uploadFileZone.mainLoadingMsg')
  };
  const defaultProps = {
    status: 'info',
    title: t('pageWithUpload.uploadFileZone.mainDefaultMsg')
  };
  const errorProps = {
    status: 'error',
    title: fileErrorMessage
  };
  const successProps = {
    status: 'success',
    title: uploadSuccess
  };

  const contentStatusProps = {
    ['loading']: loadingProps,
    ['error']: errorProps,
    ['success']: successProps,
    ['default']: defaultProps
  };

  return (
    <div id="contentStatus" className={classNames(classes.root, { [classes.disabled]: disabled })}>
      <ContentStatus {...contentStatusProps[uploadStatus]} className={classes.statusCls} />
      <div className={classes.action}>
        <UploadFile
          uploadStatus={uploadStatus}
          title={t('pageWithUpload.uploadFileZone.title')}
          disabled={disabled}
          handleUploadData={e => handleUploadData(e, file => dispatch(uploadFile(file)))}
        />
      </div>
      {(uploadFileInfo === 'uploadEntityFile' ||
        uploadFileInfo === 'uploadRemittancesFile' ||
        uploadFileInfo === 'uploadWorkflowNotesFile' ||
        uploadFileInfo === 'uploadFile') && (
        <div className={classes.fileUploadInfoStyle}>
          <div>
            {uploadFileInfo !== 'uploadFile' ? (
              <Trans
                t={t}
                i18nKey={'pageWithUpload.uploadFileZone.maxRecords'}
                values={
                  uploadFileInfo === 'uploadEntityFile' || uploadFileInfo === 'uploadWorkflowNotesFile'
                    ? { maxRecCount: '1,000' }
                    : { maxRecCount: '5,000' }
                }
                components={{ strong: <strong /> }}
              />
            ) : (
              ''
            )}
          </div>
          <div>
            <Trans t={t} i18nKey={'pageWithUpload.uploadFileZone.fileType'} components={{ strong: <strong /> }} />
          </div>
          <div>
            <Trans
              t={t}
              i18nKey={'pageWithUpload.uploadFileZone.fileMaxSize'}
              components={{ strong: <strong /> }}
              values={
                uploadFileInfo === 'uploadWorkflowNotesFile'
                  ? { fileMaxSize: '2 MB' }
                  : uploadFileInfo === 'uploadFile'
                  ? { fileMaxSize: '50 MB' }
                  : { fileMaxSize: '20 MB' }
              }
            />
          </div>
          <div>
            {uploadFileInfo === 'uploadFile' ? (
              <Trans
                t={t}
                i18nKey={'pageWithUpload.uploadFileZone.fileNameMaxCharsSize'}
                components={{ strong: <strong /> }}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withStyles(uploadFileZoneStyles)(UploadFileZone);
