import React from 'react';

import clsx from 'clsx';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import { columnLayoutItemSizes as defaultColumnLayoutItemSizes } from 'Components/Forms/helpers';

export const useErrorProps = props => {
  const {
    meta: { error, data = {}, submitError, pristine, touched, dirtySinceLastSubmit } = {},
    meta,
    validateOnLoad,
    isValidationDisabled,
    helperText = noop,
    errorOnLoad = noop,
    errorOverride
  } = props;
  if (!isBoolean(data.ignoreErr)) {
    data.ignoreErr = false;
  }
  return !isValidationDisabled && data.ignoreErr === false
    ? {
        ...helperText(meta),
        ...error,
        ...data.error,
        ...((validateOnLoad || touched) && (errorOnLoad(meta) || error)),
        ...(!dirtySinceLastSubmit && submitError),
        ...errorOverride
      }
    : {};
};

export const useInteractiveProps = props => {
  const {
    asyncValidator = noop,
    classes = {},
    disableOverride,
    disableValidation,
    errorOverride,
    field = {},
    input,
    maxRangeValue,
    meta,
    noSuffix,
    onSelect = false,
    readOnly,
    ref,
    requiredOverride,
    rules = {},
    source: sourceOverride,
    unwrapped,
    // Layout
    useColumnLayout,
    columns = 1,
    columnLayoutProps = defaultColumnLayoutItemSizes(columns),
    placeholder,
    ...propsRest
  } = props;
  const {
    autoFocus,
    debounce = 500,
    defaultValue,
    disable,
    errorOnLoad,
    format,
    hiddenAccessibleText,
    hide,
    helperText,
    helperTextPlacement = 'bottom',
    id,
    translate,
    inputProps = {},
    label: defaultLabel,
    labelPlacement = 'top',
    max,
    multiline,
    mustBalance,
    onBlur,
    onChange,
    parse,
    required,
    unformatter,
    validation = () => [],
    validationTrigger = false,
    onLoadErrorMessage,
    source = sourceOverride,
    type,
    validateOnLoad = true,
    // Dropdown
    itemToString,
    PopperProps,
    // DateRange
    defaultHelperText,
    maxRange = 0,
    CalendarProps,
    LabelProps,
    ListProps,
    TooltipProps,
    YearDropdownProps,
    // Range
    rangeRequired,
    rangeType,
    ...fieldRest
  } = field;
  const isRequired = !isBoolean(requiredOverride) ? required : requiredOverride;
  const isDisabled = !!(disable || disableOverride);
  const isValidationDisabled = !!(isDisabled || disableValidation || readOnly);

  const label = ({ format = false, t, type, plain = false } = {}) => {
    if (['dropdown', 'list'].includes(type)) {
      return format ? defaultLabel(format) : translate ? t(...defaultLabel) : defaultLabel;
    } else {
      return format && plain ? (
        defaultLabel()
      ) : (
        <>
          {format ? defaultLabel(format) : translate ? t(...defaultLabel) : defaultLabel}
          {hiddenAccessibleText && !isDisabled && <span className="visually-hidden"> {hiddenAccessibleText}</span>}
        </>
      );
    }
  };

  const ariaLabel = ({ format = false, t } = {}) => {
    return [
      format
        ? defaultLabel(format)
        : translate
        ? t(...defaultLabel)
        : typeof defaultLabel === 'object'
        ? clsx(...defaultLabel.props.children.filter(x => typeof x === 'string')).trim()
        : defaultLabel,
      hiddenAccessibleText && !isDisabled && `${hiddenAccessibleText}`
    ].join('. ');
  };

  return {
    ariaLabel,
    autoFocus,
    classes,
    defaultValue,
    errorOnLoad,
    errorOverride,
    format,
    fieldRest,
    helperText,
    helperTextPlacement,
    hide,
    id,
    input,
    inputProps,
    isDisabled,
    isRequired,
    isValidationDisabled,
    label,
    labelPlacement,
    maxRangeValue: maxRangeValue || max,
    meta,
    mustBalance,
    noSuffix,
    parse,
    onBlur,
    onChange,
    onSelect,
    propsRest,
    rangeRequired,
    readOnly,
    rules,
    source,
    type,
    unformatter,
    unwrapped,
    validation,
    validateOnLoad,
    LabelProps,
    TooltipProps,
    // Layout
    columnLayoutProps,
    useColumnLayout,
    // Dropdown
    itemToString,
    PopperProps,
    // DateRange
    defaultHelperText,
    maxRange,
    CalendarProps,
    ListProps,
    YearDropdownProps,
    // Range
    rangeType,
    placeholder
  };
};
