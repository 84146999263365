import moment from 'moment-business-days';
import i18n from 'i18n';

import {
  CAPTURE_HISTORY,
  CAPTURE_RESULTS,
  DECISION_MANAGER_HISTORY,
  DECISIONS_REQUIRING_ACTION,
  DOWNLOAD_CENTER,
  ENTITY_LIST,
  ENTITIES_REQUIRING_ACTIVATION,
  HCEOBEXCEPTIONS,
  INBOX,
  INVOICE_SEARCH,
  REMITTANCE_ADVICE_LIST,
  TRANSACTIONS,
  TRANSACTIONS_ASSIGNED_TO_ME,
  TRANSACTIONS_REQUIRING_ACTION,
  USER_ADMIN,
  VIEW_USER,
  STOP_GO
} from 'Constants/routes';

export default t => ({
  server: { dateFormat: 'YYYY-MM-DD' },
  filter: {
    section: {
      maxFieldCount: 4
    },
    openByDefault: [
      CAPTURE_HISTORY,
      CAPTURE_RESULTS,
      DECISION_MANAGER_HISTORY,
      DECISIONS_REQUIRING_ACTION,
      DOWNLOAD_CENTER,
      ENTITY_LIST,
      ENTITIES_REQUIRING_ACTIVATION,
      HCEOBEXCEPTIONS,
      INBOX,
      INVOICE_SEARCH,
      REMITTANCE_ADVICE_LIST,
      TRANSACTIONS,
      TRANSACTIONS_ASSIGNED_TO_ME,
      TRANSACTIONS_REQUIRING_ACTION,
      STOP_GO
    ],
    simpleList: [
      DOWNLOAD_CENTER,
      ENTITY_LIST,
      ENTITIES_REQUIRING_ACTIVATION,
      INBOX,
      USER_ADMIN + VIEW_USER,
      VIEW_USER,
      CAPTURE_HISTORY,
      CAPTURE_RESULTS
    ]
  },
  list: {
    rowCount: 3
  },
  grid: {
    [TRANSACTIONS]: {
      rowHeight: 21,
      maxRows: 5000
    },
    [REMITTANCE_ADVICE_LIST]: {
      rowHeight: 21,
      maxRows: 2000
    },
    [CAPTURE_HISTORY]: {
      rowHeight: 21,
      maxRows: 2000
    },
    [CAPTURE_RESULTS]: {
      rowHeight: 21,
      maxRows: 2000
    },
    [DECISION_MANAGER_HISTORY]: {
      rowHeight: 21,
      maxRows: 5000
    },
    [DECISIONS_REQUIRING_ACTION]: {
      rowHeight: 21,
      maxRows: 2000
    },
    [DOWNLOAD_CENTER]: {
      rowHeight: 21,
      maxRows: 2000
    },
    [ENTITY_LIST]: {
      rowHeight: 21,
      maxRows: 2000
    },
    [ENTITIES_REQUIRING_ACTIVATION]: {
      rowHeight: 21,
      maxRows: 2000
    },
    [HCEOBEXCEPTIONS]: {
      rowHeight: 21,
      maxRows: 2000
    },
    [INBOX]: {
      rowHeight: 21,
      maxRows: 2000
    },
    [INVOICE_SEARCH]: {
      rowHeight: 21,
      maxRows: 2000
    },
    [TRANSACTIONS_ASSIGNED_TO_ME]: {
      rowHeight: 21,
      maxRows: 5000
    },
    [TRANSACTIONS_REQUIRING_ACTION]: {
      rowHeight: 21,
      maxRows: 5000
    },
    [STOP_GO]: {
      rowHeight: 21,
      maxRows: 2000
    },
    [VIEW_USER]: {
      rowHeight: 21,
      maxRows: 8500
    },
    [USER_ADMIN + VIEW_USER]: {
      rowHeight: 21,
      maxRows: 8500
    },
    ['/']: {
      rowHeight: 21,
      maxRows: 2000
    }
  },
  common: {
    search: {
      maxRange: 92,
      minPastValue: {
        default: [13, 'month'],
        archive: [10, 'year']
      },
      maxFutureValue: {
        default: [2, 'year']
      },
      calendarYearRange: {
        // [past, future]
        default: [2, 2],
        archive: [10, 2]
      },
      calendarListProps: {
        'aria-label': t('calendar.label.calendarPresets'),
        source: [
          {
            label: t('calendar.label.currentDay'),
            getDateRange: () => ({
              startDate: moment().isBusinessDay() ? moment() : moment().nextBusinessDay(),
              endDate: moment().isBusinessDay() ? moment() : moment().nextBusinessDay()
            })
          },
          {
            label: t('calendar.label.previousDay'),
            getDateRange: () => ({
              startDate: moment().prevBusinessDay(),
              endDate: moment().prevBusinessDay()
            })
          },
          {
            label: t('calendar.label.previousWeek'),
            getDateRange: () => ({
              startDate: moment().subtract(1, 'week'),
              endDate: moment()
            })
          },
          {
            label: t('calendar.label.previousMonth'),
            getDateRange: () => ({
              startDate: moment().subtract(1, 'month'),
              endDate: moment()
            })
          },
          {
            label: t('calendar.label.lastThreeMonths'),
            getDateRange: () => ({
              startDate: moment().subtract(3, 'month'),
              endDate: moment()
            })
          }
        ]
      }
    }
  },
  cira: {
    search: {
      minPastValue: {
        default: [3, 'month']
      },
      maxFutureValue: {
        default: [0, 'day']
      }
    }
  },
  invoice: {
    search: {
      maxRange: {
        default: [3, 'month']
      },
      minPastValue: {
        default: [4, 'month'],
        archive: [10, 'year']
      }
    }
  },
  tira: {
    search: {
      startDateValue: {
        default: [32, 'days']
      }
    }
  },
  hceob: {
    search: {
      startDateValue: {
        default: [1, 'days']
      }
    }
  },
  tatm: {
    search: {
      startDateValue: {
        default: [92, 'days']
      }
    }
  },
  dm: {
    search: {
      minPastValue: [90, 'day'],
      maxFutureValue: [31, 'day'],
      today: [0, 'day']
    }
  },
  entity: {
    createdDate: {
      minPastValue: [20, 'year'],
      maxFutureValue: [0, 'day'],
      calendarYearRange: [20, 0]
    }
  },
  customReports: {
    startDate: {
      minPastValue: [-1, 'day'],
      maxFutureValue: [20, 'year']
    }
  }
});
