import api from 'api';
import i18n from 'i18n';
import { httpErrorTypes, errorActions } from 'utility/error';
import { CREATE } from '../modeTypes';
const LOAD_ALERT_TYPES_REQUEST = 'LOAD_ALERT_TYPES_REQUEST';
const LOAD_ALERT_TYPES_SUCCESS = 'LOAD_ALERT_TYPES_SUCCESS';
const LOAD_ALERT_TYPES_FAILURE = 'LOAD_ALERT_TYPES_FAILURE';
const LOAD_ALERT_CRITERIA_REQUEST = 'LOAD_ALERT_CRITERIA_REQUEST';
const LOAD_ALERT_CRITERIA_SUCCESS = 'LOAD_ALERT_CRITERIA_SUCCESS';
const LOAD_ALERT_CRITERIA_FAILURE = 'LOAD_ALERT_CRITERIA_FAILURE';
const SAVE_ALERT_REQUEST = 'SAVE_ALERT_REQUEST';
const SAVE_ALERT_SUCCESS = 'SAVE_ALERT_SUCCESS';
const SAVE_ALERT_FAILURE = 'SAVE_ALERT_FAILURE';
const LOAD_ALERT_DETAILS_REQUEST = 'LOAD_ALERT_DETAILS_REQUEST';
const LOAD_ALERT_DETAILS_SUCCESS = 'LOAD_ALERT_DETAILS_SUCCESS';
const LOAD_ALERT_DETAILS_FAILURE = 'LOAD_ALERT_DETAILS_FAILURE';
const DELETE_ALERT_REQUEST = 'DELETE_ALERT_REQUEST';
const DELETE_ALERT_SUCCESS = 'DELETE_ALERT_SUCCESS';
const DELETE_ALERT_FAILURE = 'DELETE_ALERT_FAILURE';
const RESET_ALERT = 'RESET_ALERT';
const LOAD_ALERT_MANAGE_REQUEST = 'LOAD_ALERT_MANAGE_REQUEST';
const LOAD_ALERT_MANAGE_SUCCESS = 'LOAD_ALERT_MANAGE_SUCCESS';
const LOAD_ALERT_MANAGE_FAILURE = 'LOAD_ALERT_MANAGE_FAILURE';

const alertsManageError = {
  LOAD_ALERT_MANAGE: 'LOAD_ALERT_MANAGE',
  LOAD_ALERT_MANAGE_NO_DATA: 'LOAD_ALERT_MANAGE_NO_DATA'
};

const loadAlertTypes = t => {
  return {
    types: [LOAD_ALERT_TYPES_REQUEST, LOAD_ALERT_TYPES_SUCCESS, LOAD_ALERT_TYPES_FAILURE],
    callAPI: (state, { customerId, userId }) => api.fetch(`alertssvc/v0/cust/${customerId}/user/${userId}/alert-types`),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: t('alerts.errorMessages.unableToLoadAlertTypes'),
    modalActions: { DEFAULT: errorActions.GO_BACK }
  };
};

const loadAlertCriteria = (endpoint, t) => {
  return {
    types: [LOAD_ALERT_CRITERIA_REQUEST, LOAD_ALERT_CRITERIA_SUCCESS, LOAD_ALERT_CRITERIA_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.fetch(`alertssvc/v0/cust/${customerId}/user/${userId}/${endpoint}/criteria`),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: t('alerts.errorMessages.unableToLoadAlertCriteria'),
    modalActions: { DEFAULT: errorActions.GO_BACK }
  };
};

const saveAlert = (action, t, endpoint, payload, alertId ) => {
  return {
    types: [SAVE_ALERT_REQUEST, SAVE_ALERT_SUCCESS, SAVE_ALERT_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.save(
        alertId
          ? `alertssvc/v0/cust/${customerId}/user/${userId}/${endpoint}/${alertId}`
          : `alertssvc/v0/cust/${customerId}/user/${userId}/${endpoint}`,
        payload
      ),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: action === CREATE ? t('alerts.errorMessages.unableToCreateNewAlert'): t('alerts.errorMessages.unableToSaveAlert'),
    successDisplayType: 'modal',
    successTitle: t('alerts.errors.confirmation'),
    successModalActions: { DEFAULT: errorActions.GO_BACK }
  };
};

const loadAlertDetails = (alertId, endpoint, t) => {
  return {
    types: [LOAD_ALERT_DETAILS_REQUEST, LOAD_ALERT_DETAILS_SUCCESS, LOAD_ALERT_DETAILS_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.fetch(`alertssvc/v0/cust/${customerId}/user/${userId}/${endpoint}/${alertId}`),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: t('alerts.errorMessages.unableToLoadAlertDetails'),
    modalActions: { DEFAULT: errorActions.GO_BACK }
  };
};

const deleteAlert = (payload, successHandler, t) => {
  return {
    types: [DELETE_ALERT_REQUEST, DELETE_ALERT_SUCCESS, DELETE_ALERT_FAILURE],
    callAPI: (state, { customerId, userId }) =>
      api.save(`alertssvc/v0/cust/${customerId}/user/${userId}/delete`, { alertIds: payload }),
    httpErrorTypes,
    successDisplayType: 'toast',
    successTitle: t('alerts.titles.success'),
    errorDisplayType: 'modal',
    errorTitle: t('alerts.errors.unableToDelete'),
    dispatchOnSuccess: [successHandler]
  };
};

const loadAlertManage = () => ({
  types: [LOAD_ALERT_MANAGE_REQUEST, LOAD_ALERT_MANAGE_SUCCESS, LOAD_ALERT_MANAGE_FAILURE],
  callAPI: (state, { customerId, userId }) => api.fetch(`alertssvc/v0/cust/${customerId}/user/${userId}/alerts`),
  httpErrorTypes: {
    DEFAULT: alertsManageError.LOAD_ALERT_MANAGE,
    404: {
      type: alertsManageError.LOAD_ALERT_MANAGE_NO_DATA,
      message: i18n.t('alerts.messages.noAlertsCreated')
    }
  }
});

const resetAlert = () => {
  return { type: RESET_ALERT };
};

export {
  LOAD_ALERT_TYPES_FAILURE,
  LOAD_ALERT_TYPES_REQUEST,
  LOAD_ALERT_TYPES_SUCCESS,
  loadAlertTypes,
  LOAD_ALERT_CRITERIA_FAILURE,
  LOAD_ALERT_CRITERIA_REQUEST,
  LOAD_ALERT_CRITERIA_SUCCESS,
  loadAlertCriteria,
  SAVE_ALERT_REQUEST,
  SAVE_ALERT_SUCCESS,
  SAVE_ALERT_FAILURE,
  LOAD_ALERT_DETAILS_FAILURE,
  LOAD_ALERT_DETAILS_REQUEST,
  LOAD_ALERT_DETAILS_SUCCESS,
  loadAlertDetails,
  saveAlert,
  RESET_ALERT,
  DELETE_ALERT_REQUEST,
  DELETE_ALERT_SUCCESS,
  DELETE_ALERT_FAILURE,
  deleteAlert,
  LOAD_ALERT_MANAGE_FAILURE,
  LOAD_ALERT_MANAGE_REQUEST,
  LOAD_ALERT_MANAGE_SUCCESS,
  alertsManageError,
  loadAlertManage,
  resetAlert
};
