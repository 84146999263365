import { Suspense, lazy } from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import classNames from 'classnames';

import { SpinnerWithText } from 'Components/Spinner';

const Sitemap = lazy(() => import(/* webpackChunkName: "Sitemap" */ './Sitemap')); // eslint-disable-line no-inline-comments
const ScanPage = lazy(() => import(/* webpackChunkName: "ScanPage" */ './ScanPage/DynamicScanPage')); // eslint-disable-line no-inline-comments
const DisplayPrintSettings = lazy(
  () => import(/* webpackChunkName: "DisplayPrintSettings" */ './Settings/DisplayPrintSettings') // eslint-disable-line no-inline-comments
);
const Inbox = lazy(() => import(/* webpackChunkName: "Inbox" */ './Alerts/Inbox')); // eslint-disable-line no-inline-comments
const Manage = lazy(() => import(/* webpackChunkName: "Manage" */ './Alerts/Manage')); // eslint-disable-line no-inline-comments
const EditAlert = lazy(() => import(/* webpackChunkName: "EditAlert" */ './Alerts/EditAlert')); // eslint-disable-line no-inline-comments
const InvoiceDetails = lazy(
  () => import(/* webpackChunkName: "InvoiceDetails" */ './InvoiceSearch/InvoiceDetailsPage') // eslint-disable-line no-inline-comments
);
const InvoiceSearch = lazy(() => import(/* webpackChunkName: "InvoiceSearch" */ './InvoiceSearch')); // eslint-disable-line no-inline-comments
const CreateAlert = lazy(() => import(/* webpackChunkName: "CreateAlert" */ './Alerts/CreateAlert')); // eslint-disable-line no-inline-comments
const DownloadCenter = lazy(() => import(/* webpackChunkName: "DownloadCenter" */ './DownloadCenter')); // eslint-disable-line no-inline-comments
const CustomerClassification = lazy(
  () => import(/* webpackChunkName: "CustomerClassification" */ './Entity/CustomerClassification') // eslint-disable-line no-inline-comments
);
const EntityList = lazy(() => import(/* webpackChunkName: "EntityList" */ './Entity/EntityList')); // eslint-disable-line no-inline-comments
const ConcourseEntityList = lazy(() =>
  import(/* webpackChunkName: "ConcourseEntityList" */ './Entity/EntityList/ConcourseEntityList')
); // eslint-disable-line no-inline-comments
const ConcourseFileImport = lazy(() =>
  import(/* webpackChunkName: "ConcourseFileImport" */ './Entity/EntityList/ConcourseEntityList')
); // eslint-disable-line no-inline-comments
const ConcourseActionItems = lazy(() =>
  import(/* webpackChunkName: "ConcourseActionItems" */ './Entity/EntityList/ConcourseEntityList')
); // eslint-disable-line no-inline-comments
const ConcourseCreatePayment = lazy(() =>
  import(/* webpackChunkName: "ConcourseCreatePayment" */ './Entity/EntityList/ConcourseEntityList')
); // eslint-disable-line no-inline-comments
const EntityLookup = lazy(() => import(/* webpackChunkName: "EntityLookup" */ './Entity/EntityLookup')); // eslint-disable-line no-inline-comments
const UploadEntity = lazy(() => import(/* webpackChunkName: "UploadEntity" */ './Entity/UploadEntity')); // eslint-disable-line no-inline-comments
const EntityDetails = lazy(() => import(/* webpackChunkName: "EntityDetails" */ './Entity/EntityDetails')); // eslint-disable-line no-inline-comments
const EditRemittanceAdvice = lazy(
  () => import(/* webpackChunkName: "EditRemittanceAdvice" */ './RemittanceAdviceDetails/EditRemittanceAdvice') // eslint-disable-line no-inline-comments
);
const CreateRemittanceAdvice = lazy(
  () => import(/* webpackChunkName: "CreateRemittanceAdvice" */ './RemittanceAdviceDetails/CreateRemittanceAdvice') // eslint-disable-line no-inline-comments
);
const OnlineReportResults = lazy(
  () => import(/* webpackChunkName: "OnlineReportResults" */ './Reports/OnlineReportResults') // eslint-disable-line no-inline-comments
);
const OnlineReports = lazy(() => import(/* webpackChunkName: "OnlineReports" */ './Reports/OnlineReports')); // eslint-disable-line no-inline-comments
const CreateReport = lazy(() => import(/* webpackChunkName: "CreateReport" */ './Reports/CustomReports/CreateReport')); // eslint-disable-line no-inline-comments
const RemittanceAdviceList = lazy(
  () => import(/* webpackChunkName: "RemittanceAdviceList" */ './RemittanceAdviceList') // eslint-disable-line no-inline-comments
);
const UploadWorkFlowNotes = lazy(
  () => import(/* webpackChunkName: "UploadWorkFlowNotes" */ './UploadWorkFlowNotes') // eslint-disable-line no-inline-comments
);
const UploadRemittanceAdvices = lazy(
  () => import(/* webpackChunkName: "UploadRemittanceAdvices" */ './UploadRemittanceAdvices') // eslint-disable-line no-inline-comments
);
const Support = lazy(() => import(/* webpackChunkName: "Support" */ './Support')); // eslint-disable-line no-inline-comments
const UserAdmin = lazy(() => import(/* webpackChunkName: "UserAdmin" */ './UserAdmin')); // eslint-disable-line no-inline-comments
const ExportImages = lazy(() => import(/* webpackChunkName: "ExportImages" */ './ExportImages')); // eslint-disable-line no-inline-comments
const Transactions = lazy(() => import(/* webpackChunkName: "Transactions" */ './Transactions')); // eslint-disable-line no-inline-comments
const CaptureDetails = lazy(() => import(/* webpackChunkName: "CaptureDetails" */ './CaptureDetails')); // eslint-disable-line no-inline-comments
const CaptureResults = lazy(() => import(/* webpackChunkName: "CaptureResults" */ './CaptureResults')); // eslint-disable-line no-inline-comments
const DecisionDetails = lazy(() => import(/* webpackChunkName: "DecisionDetails" */ './DecisionDetails')); // eslint-disable-line no-inline-comments
const DecisionManager = lazy(() => import(/* webpackChunkName: "DecisionManager" */ './DecisionManager')); // eslint-disable-line no-inline-comments
const TransactionDetail = lazy(() => import(/* webpackChunkName: "TransactionDetail" */ './TransactionDetail')); // eslint-disable-line no-inline-comments
const ReasonCodes = lazy(() => import(/* webpackChunkName: "ReasonCodes" */ './ReasonCodes')); // eslint-disable-line no-inline-comments
const LogOut = lazy(() => import(/* webpackChunkName: "LogOut" */ './LogOut')); // eslint-disable-line no-inline-comments

import InvoiceIdentificationPatterns from './InvoiceIdentificationPatterns';
import CloneInvoicePattern from './InvoicePatternDetails/CloneInvoicePattern';
import EditInvoicePattern from './InvoicePatternDetails/EditInvoicePattern';
import SupportComponent from './Support';
import CreateInvoicePattern from './InvoicePatternDetails/CreateInvoicePattern';
import StopGo from './Stopgo';

import { SIDEPANEL_FILTER, SIDEPANEL_INBOX } from 'Constants/sidePanel';
import {
  CAPTURE_DETAIL,
  CAPTURE_HISTORY,
  CAPTURE_RESULTS,
  CLONE_INVOICE_PATTERN,
  CONCOURSE_CREATE_PAYMENT,
  CONCOURSE_ENTITY_MANAGER,
  CONCOURSE_IRA,
  CREATE_ALERT,
  CREATE_INVOICE_PATTERN,
  CREATE_REPORT,
  CREATE_REMITTANCE_ADVICE,
  CUSTOMER_CLASSIFICATION,
  DECISION_MANAGER_HISTORY,
  DECISION_MANAGER_DETAILS,
  DECISIONS_REQUIRING_ACTION,
  DECISIONS_REQUIRING_ACTION_DETAILS,
  DEFAULT_ROUTE,
  DISPLAYPRINTSETTINGS,
  DOWNLOAD_CENTER,
  ENTITY_LOOKUP,
  EDIT_ALERT,
  EDIT_INVOICE_PATTERN,
  EDIT_REPORT,
  EXPORT_IMAGE,
  HCEOBEXCEPTIONS,
  INBOX,
  INVOICE_IDENTIFICATION_PATTERNS,
  INVOICE_DETAILS,
  INVOICE_SEARCH,
  LANDING_PAGE,
  LOGOUT,
  MANAGE,
  ONLINE_REPORTS,
  ONLINE_REPORT_RESULTS,
  ENTITY_CREATE,
  ENTITY_DETAILS,
  ENTITY_LIST,
  ENTITIES_REQUIRING_ACTIVATION,
  ENTITY_UPLOAD,
  REASON_CODES,
  REMITTANCE_ADVICE_DETAILS,
  REMITTANCE_ADVICE_LIST,
  SCAN,
  SITEMAP,
  STOP_GO,
  SUPPORT_PAGE,
  TRANSACTION_DETAIL,
  TRANSACTIONS,
  TRANSACTIONS_ASSIGNED_TO_ME,
  TRANSACTIONS_REQUIRING_ACTION,
  UPLOAD_CONCOURSE_ENTITIES,
  UPLOAD_WORKFLOW_NOTES,
  UPLOAD_REMITTANCE_ADVICES,
  USER_ADMIN
} from 'Constants/routes';
import { useSelector } from 'react-redux';
export const getPageComponent = Page => {
  return () => {
    return <Page />;
  };
};

export const getLandingPage = landingRoute => {
  const defaultLandingPage = LANDING_PAGE[landingRoute?.id] || LANDING_PAGE[DEFAULT_ROUTE.id];

  return () => {
    return <Redirect to={defaultLandingPage} />;
  };
};

export const Router = props => {
  const {
    broadcastalerts,
    navigation: { isFetching, landingRoute },
    sidePanels: { displaying },
    users: {
      status,
      errorMessage,
      isServiceModeError = errorMessage === 'MAINTAINMODE',
      dialogTitle = isServiceModeError ? 'System Outage' : 'Login Error'
    },
    settings: { userCustomer, isFullPortal, userSettings },
    sidepanel = displaying === SIDEPANEL_FILTER || displaying === SIDEPANEL_INBOX
  } = useSelector(state => state);

  return (
    <div
      className={classNames('content', {
        'full-page': sidepanel
      })}
    >
      <Suspense fallback={<SpinnerWithText message="Loading page..." />}>
        {!isFetching && <Route path="/" exact render={getLandingPage(landingRoute)} />}
        <Route path={SITEMAP} render={getPageComponent(Sitemap)} />
        <Route path={SCAN} render={getPageComponent(ScanPage)} />
        <Route path={CAPTURE_RESULTS} render={getPageComponent(CaptureResults)} />
        <Route path={CAPTURE_HISTORY} render={getPageComponent(CaptureResults)} />
        <Route path={CONCOURSE_ENTITY_MANAGER} render={getPageComponent(ConcourseEntityList)} />
        <Route path={UPLOAD_CONCOURSE_ENTITIES} render={getPageComponent(ConcourseFileImport)} />
        <Route path={CONCOURSE_IRA} render={getPageComponent(ConcourseActionItems)} />
        <Route path={CONCOURSE_CREATE_PAYMENT} render={getPageComponent(ConcourseCreatePayment)} />
        <Route path={TRANSACTIONS} render={getPageComponent(Transactions)} />
        <Route path={TRANSACTIONS_ASSIGNED_TO_ME} render={getPageComponent(Transactions)} />
        <Route path={TRANSACTIONS_REQUIRING_ACTION} render={getPageComponent(Transactions)} />
        <Route path={UPLOAD_WORKFLOW_NOTES} render={getPageComponent(UploadWorkFlowNotes)} />
        <Route path={UPLOAD_REMITTANCE_ADVICES} render={getPageComponent(UploadRemittanceAdvices)} />
        <Route path={USER_ADMIN} render={getPageComponent(UserAdmin)} />
        <Route path={ENTITY_LOOKUP} render={getPageComponent(EntityLookup)} />
        <Route path={ENTITY_LIST} render={getPageComponent(EntityList)} />
        <Route path={ENTITIES_REQUIRING_ACTIVATION} render={getPageComponent(EntityList)} />
        <Route path={DISPLAYPRINTSETTINGS} render={getPageComponent(DisplayPrintSettings)} />
        <Route path={CAPTURE_DETAIL} component={CaptureDetails} />
        <Route path={TRANSACTION_DETAIL} component={TransactionDetail} />
        <Route path={HCEOBEXCEPTIONS} render={getPageComponent(Transactions)} />
        <Route path={INBOX} component={Inbox} />
        <Route path={INVOICE_IDENTIFICATION_PATTERNS} component={InvoiceIdentificationPatterns} />
        <Route path={INVOICE_DETAILS} component={InvoiceDetails} />
        <Route path={INVOICE_SEARCH} component={InvoiceSearch} />
        <Route path={CLONE_INVOICE_PATTERN} component={CloneInvoicePattern} />
        <Route path={CREATE_REPORT} component={CreateReport} />
        <Route path={EDIT_REPORT} component={CreateReport} />
        <Route path={CREATE_ALERT} component={CreateAlert} />
        <Route path={CREATE_INVOICE_PATTERN} component={CreateInvoicePattern} />
        <Route path={EDIT_ALERT} component={EditAlert} />
        <Route path={EDIT_INVOICE_PATTERN} component={EditInvoicePattern} />
        <Route path={MANAGE} component={Manage} />
        <Route path={EXPORT_IMAGE} component={ExportImages} />
        <Route exact path={ONLINE_REPORTS + '/:id'} component={OnlineReports} />
        <Route path={ONLINE_REPORTS + '/:id' + ONLINE_REPORT_RESULTS} component={OnlineReportResults} />
        <Route path={DOWNLOAD_CENTER} component={DownloadCenter} />
        <Route path={ENTITY_CREATE} render={getPageComponent(EntityDetails)} />
        <Route path={ENTITY_DETAILS} render={getPageComponent(EntityDetails)} />
        <Route path={ENTITY_UPLOAD} render={getPageComponent(UploadEntity)} />
        <Route path={CUSTOMER_CLASSIFICATION} render={getPageComponent(CustomerClassification)} />
        <Route path={REASON_CODES} render={getPageComponent(ReasonCodes)} />
        <Route path={SUPPORT_PAGE} component={Support} />
        <Route path={REMITTANCE_ADVICE_LIST} component={RemittanceAdviceList} />
        <Route path={CREATE_REMITTANCE_ADVICE} component={CreateRemittanceAdvice} />
        <Route path={REMITTANCE_ADVICE_DETAILS} component={EditRemittanceAdvice} />
        <Route exact path={DECISION_MANAGER_HISTORY} component={DecisionManager} />
        <Route exact path={DECISIONS_REQUIRING_ACTION} component={DecisionManager} />
        <Route path={DECISION_MANAGER_DETAILS} render={getPageComponent(DecisionDetails)} />
        <Route path={DECISIONS_REQUIRING_ACTION_DETAILS} render={getPageComponent(DecisionDetails)} />
        <Route path={STOP_GO} component={StopGo} />
        <Route path={LOGOUT} render={getPageComponent(LogOut)} />
      </Suspense>
    </div>
  );
};
