import api from 'api';
import cloneDeep from 'lodash/cloneDeep';
import { mapValuesToPayload } from 'routes/utils/payload';
import { httpErrorTypes } from 'utility/error';
import { transactionURL } from './utils';
import { FIELD_TYPES, unformatPayload } from 'hooks/useFieldFormatter';

export const LOAD_TRANSACTIONS_REQUEST = 'LOAD_TRANSACTIONS_REQUEST';
export const LOAD_TRANSACTIONS_SUCCESS = 'LOAD_TRANSACTIONS_SUCCESS';
export const LOAD_TRANSACTIONS_FAILURE = 'LOAD_TRANSACTIONS_FAILURE';
export const LOAD_ADVANCE_FILTER_REQUEST = 'LOAD_ADVANCE_FILTER_REQUEST';
export const LOAD_ADVANCE_FILTER_SUCCESS = 'LOAD_ADVANCE_FILTER_SUCCESS';
export const LOAD_ADVANCE_FILTER_FAILURE = 'LOAD_ADVANCE_FILTER_FAILURE';
export const EXPORT_TRANSACTION_REQUEST = 'EXPORT_TRANSACTION_REQUEST';
export const EXPORT_TRANSACTION_SUCCESS = 'EXPORT_TRANSACTION_SUCCESS';
export const EXPORT_TRANSACTION_FAILURE = 'EXPORT_TRANSACTION_FAILURE';
export const ASSIGN_TRANSACTIONS_REQUEST = 'ASSIGN_TRANSACTIONS_REQUEST';
export const ASSIGN_TRANSACTIONS_SUCCESS = 'ASSIGN_TRANSACTIONS_SUCCESS';
export const ASSIGN_TRANSACTIONS_FAILURE = 'ASSIGN_TRANSACTIONS_FAILURE';
export const RESET_TRANSACTION_FILTER = 'RESET_TRANSACTION_FILTER';
export const BULK_UPDATE_WORKFLOW_STATUSES_REQUEST = 'BULK_UPDATE_WORKFLOW_STATUSES_REQUEST';
export const BULK_UPDATE_WORKFLOW_STATUSES_SUCCESS = 'BULK_UPDATE_WORKFLOW_STATUSES_SUCCESS';
export const BULK_UPDATE_WORKFLOW_STATUSES_FAILURE = 'BULK_UPDATE_WORKFLOW_STATUSES_FAILURE';

export const loadTransactions = (payload, serviceAction = '') => (dispatch, getState) => {
  const actionQueryParam =
    serviceAction === 'transactions-requiring-action' || serviceAction === 'hc-eob-exception-action'
      ? `search?action=${serviceAction}`
      : serviceAction === 'transactions-assigned-to-me-action'
      ? 'transactions-assigned-to-me-search'
      : 'search';
  const data = cloneDeep(payload);
  const userSettings = getState().settings?.userSettings;

  const fieldsToUnformat = { amount: FIELD_TYPES.AMOUNT };
  const unformattedPayload = unformatPayload(fieldsToUnformat, data)(userSettings);

  const newPayload = mapValuesToPayload(unformattedPayload);
  return dispatch({
    types: [LOAD_TRANSACTIONS_REQUEST, LOAD_TRANSACTIONS_SUCCESS, LOAD_TRANSACTIONS_FAILURE],
    callAPI: (state, options) => api.save(transactionURL(`transaction/${actionQueryParam}`), newPayload),
    httpErrorTypes
  });
};

export const loadAdvanceFilter = action => ({
  types: [LOAD_ADVANCE_FILTER_REQUEST, LOAD_ADVANCE_FILTER_SUCCESS, LOAD_ADVANCE_FILTER_FAILURE],
  callAPI: (state, options) => api.fetch(transactionURL(`transaction-advanced-filter?action=${action}`)),
  httpErrorTypes
});

export const exportTransaction = (t, transactionsData, serviceAction = '') => (dispatch, getState) => {
  const actionQueryParam = serviceAction ? `?action=${serviceAction}` : '';
  const data = cloneDeep(transactionsData);
  const userSettings = getState().settings.userSettings;
  const fieldsToUnformat = { amount: FIELD_TYPES.AMOUNT };
  const unformattedPayload = unformatPayload(fieldsToUnformat, data)(userSettings);
  const newPayload = mapValuesToPayload(unformattedPayload);

  return dispatch({
    types: [EXPORT_TRANSACTION_REQUEST, EXPORT_TRANSACTION_SUCCESS, EXPORT_TRANSACTION_FAILURE],
    callAPI: (state, options) => api.save(transactionURL(`transaction/export/data${actionQueryParam}`), newPayload),
    httpErrorTypes,
    errorDisplayType: 'modal',
    errorTitle: t('transactions.error.exportError')
  });
};

export const assignTransactionsToUser = (t, payload, transactionPayload, status, transactionServiceAction = '') => ({
  types: [ASSIGN_TRANSACTIONS_REQUEST, ASSIGN_TRANSACTIONS_SUCCESS, ASSIGN_TRANSACTIONS_FAILURE],
  callAPI: (state, options) => api.save(transactionURL(`assign-transactions`), payload),
  dispatchOnSuccess: [() => loadTransactions(transactionPayload, transactionServiceAction)],
  httpErrorTypes,
  successDisplayType: 'toast',
  errorDisplayType: 'modal',
  errorTitle: t('transactions.error.assignError', { status: status })
});

export const bulkUpdateWorkflowStatuses = (
  t,
  workflowStatusPayload,
  transactionPayload,
  transactionServiceAction = ''
) => ({
  types: [
    BULK_UPDATE_WORKFLOW_STATUSES_REQUEST,
    BULK_UPDATE_WORKFLOW_STATUSES_SUCCESS,
    BULK_UPDATE_WORKFLOW_STATUSES_FAILURE
  ],
  callAPI: (state, options) => api.save(transactionURL('bulk-update-workflow-status'), workflowStatusPayload),
  dispatchOnSuccess: [() => loadTransactions(transactionPayload, transactionServiceAction)],
  httpErrorTypes,
  successDisplayType: 'toast',
  errorDisplayType: 'modal',
  errorTitle: t('transactions.error.bulkWorkflowStatusUpdate')
});

export const resetTransactionFilter = () => ({ type: RESET_TRANSACTION_FILTER });
